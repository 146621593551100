<!-- OpcionesTab.vue -->
<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Configuración SMS</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-flow: row wrap; justify-content: space-between;">
                    <label for="comp">Compañía</label>
                    <select style="width: 300px; height: 80%; margin-left: 10px;" id="comp" v-model="companiaSeleccionada">
                            <option value="">Todas</option>
                            <option v-for="compania in companias" :key="compania.id" :value="compania.id">{{ compania.nombre }}({{ compania.prefijo }})</option>
                    </select>
                </div>
                <div>
                    <textarea v-model="ta" rows="3" style="width: 100%;" id=""></textarea>
                </div>
                <div class="d-flex justify-content-start">
                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="visible=true">Palabras claves</button>
                </div>
                <div class="d-flex justify-content-between" style="margin-top: 20px;margin-bottom: 20px;">
                    <label>Carácteres ({{ caracteres }})</label>
                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="guardar">Guardar</button>
                </div>
                <div  class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Compañía</th>
                                <th>Texto</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="textoGeneral">
                                <td>Todas</td>
                                <td>{{ textoGeneral }}</td>
                                <td></td>
                            </tr>
                            <tr v-for="comp in companiasCreadas" :key="comp.id">
                                <td>{{ comp.compania.nombre }}({{ comp.compania.prefijo }})</td>
                                <td>{{ comp.texto }}</td>
                                <td style="text-align: end;">
                                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="eliminar(comp.id_compania_sms)">
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <steclaves :visible="visible" :claves="claves" @update:visible="cerrarModal"/>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import steclaves from './ste_claves.vue';
export default {
    data() {
        return {
            visible: false,
            companias:[],
            companiasCreadas:[],
            companiaSeleccionada:'',        
            textoGeneral:'',
            caracteres:0,
            ta:'',
            claves:{},
        };
    },components: {
        steclaves,
    },
    watch: {
        ta(newVal) {
            this.caracteres = newVal.length;
        },
        companias(){
            this.cargarCompaniasCreadas();
        }
    },
    methods:{
        async guardar(){
            const api = new PwgsApi();
            if(this.companiaSeleccionada==''){
                const body1 = {"texto_SMS":this.ta};
                await api.put('ste', body1);
            }else{
                const companiaSeleccion = this.companiaSeleccionada;
                const body = {"sms_companias":{[companiaSeleccion]:this.ta}};
                await api.put('ste', body);
            }
            this.cargarCompaniasCreadas();
        },
        async eliminar(id){
            const api = new PwgsApi();
            const body = {sms_companias:[id]};
            const res = await api.deletebody('ste', body);
            console.log('eliminado',res);
            this.cargarCompaniasCreadas();
        },
        async cargarCompanias(){
            const api = new PwgsApi();
            const res = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = res.datos;
        },
        async cargarCompaniasCreadas(){
            this.companiasCreadas = [];
            const api = new PwgsApi();
            const res = await api.get('ste');
            this.claves = res.palabrasclave.palabras_clave;
            const smss = res.configuracion.sms_companias;
            const ids = Object.keys(smss);
            this.textoGeneral = res.configuracion.texto_SMS;
            this.ta = res.configuracion.texto_SMS;
            for(let com of this.companias){
                if (ids.includes(com.id)){
                    this.companiasCreadas.push(smss[com.id]);
                }
            }
        },
        cerrarModal(){
            this.visible = false;
        },
    },
    mounted() {
        this.cargarCompanias();
        this.cargarCompaniasCreadas();
    },
};
</script>
