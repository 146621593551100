<!-- OpcionesTab.vue -->
<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Opciones TPV</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div class="table-responsive">
                <table style="width: 800px;" cellpadding="5" >
                    <tbody>
                        <tr>
                            <td>
                                Pasarela de pago
                            </td>
                            <td>
                                <input type="checkbox" v-model="pasarelapago">
                            </td>
                            <td>
                                URL TPV
                            </td>
                            <td>
                                <input style="width: 100%;" v-model="urltpv"  type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                
                            </td>
                            <td>
                                
                            </td>
                            <td>
                                Numero de comercio
                            </td>
                            <td>
                                <input style="width: 100%;" v-model="numcomercio" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                
                            </td>
                            <td>
                                
                            </td>
                            <td>
                                Numero de terminal
                            </td>
                            <td>
                                <input style="width: 100%;" v-model="numterminal" type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                               
                            </td>
                            <td>
                                
                            </td>
                            <td>
                                Clave secreta de cifrado
                            </td>
                            <td>
                                <input style="width: 100%;" v-model="clave"  type="text">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Bizum
                            </td>
                            <td>
                                <input type="checkbox" v-model="bizum">
                            </td>
                            <td>
                                
                            </td>
                            <td style="text-align: right;">
                                <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="guardar">Guardar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    data() {
        return {
            datos: {},
            pasarelapago: false,
            bizum: false,
            urltpv: '',
            numcomercio: '',
            numterminal: '',
            clave: ''
        };
    },
    methods:{
        async obtenerDatos() {
            const api = new PwgsApi();
            this.datos = await api.get('ste');
            this.pasarelapago = this.datos.configuracion.pasarela_pago == 1 ? true : false;
            this.bizum = this.datos.configuracion.bizum == 1 ? true : false;
            this.urltpv = this.datos.configuracion.tpv_url;
            this.numcomercio = this.datos.configuracion.tpv_comercio;
            this.numterminal = this.datos.configuracion.tpv_terminal;
            this.clave = this.datos.configuracion.tpv_clave;
        },
        async guardar(){
            const api = new PwgsApi();
            await api.put('ste', {
                pasarela_pago: this.pasarelapago? 1 : 0,
                bizum: this.bizum? 1 : 0,
                tpv_url: this.urltpv,
                tpv_comercio: this.numcomercio,
                tpv_terminal: this.numterminal,
                tpv_clave: this.clave
            });
        }
    },
    mounted() {
        this.obtenerDatos();
    },
    watch:{
    }
};
</script>
<style scoped>
</style>
