<!-- OpcionesTab.vue -->
<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Configuración de las líneas de facturación por compañía</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; align-items: center; flex-flow: row wrap; width: 70%;">
                    <div style="width: 600px; align-items: center; display: flex; margin-right: 20px;">
                        <p style="margin:0">Línea de facturación por defecto</p>
                    </div>
                    <div style="width: 450px; display: flex; align-items: center; " >
                        <p for="ln1" style="margin:0">Línea</p>
                        <select  style="height: 80%;width: 100%;" v-model="linea1" id="ln1">
                            <option id="ln1" v-for="linea in lineas" :key="linea.id" :value="linea.id">{{ linea.nombre_serie }} ({{ linea.prefijo_factura_serie }})</option>
                        </select>
                    </div>
                </div>
                <div style="display: flex; flex-flow: row wrap; align-items: center; width: 70%; margin-top: 15px;">
                    <div style="width: 600px; display: flex; margin-right: 20px;">
                        <p for="comp">Compañía</p>
                        <select style="width: 100%;height: 80%;" id="comp" v-model="companiaSeleccionada">
                            <option v-for="compania in companias" :key="compania.id" :value="compania.id">{{ compania.nombre }}({{ compania.prefijo }})</option>
                        </select>
                    </div>
                    <div style="width: 450px; display: flex;" >
                        <p for="ln2">Línea</p>
                        <select style="width: 100%; height: 80%;" v-model="linea2" id="ln2">
                            <option  id="ln2" v-for="linea in lineas" :key="linea.id" :value="linea.id">{{ linea.nombre_serie }} ({{ linea.prefijo_factura_serie }})</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-end" style="margin-top: 20px;margin-bottom: 20px; width: 70%;">
                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="guardar">Guardar</button>
                </div>
                <div style="width: 70%;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Compañía</th>
                                <th>Línea</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="linea0">
                                <td>Todas</td>
                                <td>{{ linea0.nombre_serie }} ({{ linea0.prefijo_factura_serie }})</td>
                                <td></td>
                            </tr>
                            <tr v-for="comp in companiasCreadas" :key="comp.id">
                                <td>{{ comp.nombre }}({{ comp.prefijo }})</td>
                                <td> {{ lineasCreadas[comp.id].nombre_serie }} ({{ lineasCreadas[comp.id].prefijo_factura_serie }})</td>
                                <td style="text-align: end;">
                                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="eliminar(comp.id)">
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    data() {
        return {
            companias:[],
            companiasCreadas:[],
            companiaSeleccionada:'',
            lineas:[],
            linea1:'',
            linea2:'',
            lineasCreadas:[],
            linea0:'',
        };
    },
    methods:{
        async guardar(){
            if(this.companiaSeleccionada==''){
                alert('Selecciona una compañía');
                return;
            }
            if(this.linea2==''){
                alert('Selecciona una línea de facturación');
                return;
            }
            const companiaSeleccion = this.companiaSeleccionada;
            const api = new PwgsApi();
            const body = {"lineas_companias":{[companiaSeleccion]:this.linea2}};
            await api.put('ste', body);
            this.cargarCompaniasCreadas();
        },
        async cargarLineas(){
            const api = new PwgsApi();
            const res = await api.get('lineas-facturacion?sortField=nombre_serie&sortOrder=1');
            this.lineas = res.datos;
            
        },
        async guardarPorDefecto(){
            const api = new PwgsApi();
            const body = {"lineas_companias":{0:this.linea1}};
            await api.put('ste', body);
            this.cargarCompaniasCreadas();
        },
        async eliminar(id){
            const api = new PwgsApi();
            const body = {"lineas_companias":[id]};
            const res = await api.deletebody('ste', body);
            console.log('eliminado',res);
            this.cargarCompaniasCreadas();
        },
        async cargarCompanias(){
            const api = new PwgsApi();
            const res = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = res.datos;
        },
        async cargarCompaniasCreadas(){
            this.lineasCreadas = [];
            this.companiasCreadas = [];
            const api = new PwgsApi();
            const res = await api.get('ste');
            const lns = res.configuracion.lineas_companias;
            const ids = Object.keys(lns);
            var id0 = lns[0];
            if(id0){
                this.linea0 = this.lineas.find(ln => ln.id == id0);
                this.linea1 = id0;
            }
            for(let com of this.companias){
                if (ids.includes(com.id)){
                    var idlinea = lns[com.id];
                    if(idlinea){
                        this.companiasCreadas.push(com);                        
                        this.lineasCreadas[com.id] = this.lineas.find(ln => ln.id == idlinea);
                    }
                }
            }
        }
    },
    mounted() {
        this.cargarCompanias();
        this.cargarLineas();
        this.cargarCompaniasCreadas();
    },
    watch:{
        linea1(){
            this.guardarPorDefecto();
        },
        companias(){
            this.cargarCompaniasCreadas();
        }
    }
};
</script>
<style scoped>
select{
    margin-left: 10px;
}
</style>
