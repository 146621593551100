<!-- OpcionesTab.vue -->
<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Consultas de clientes</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div class="table-responsive">
                <table class="table  table-striped ">
                    <thead>
                        <tr>
                            <th>
                                
                            
                            </th>
                            <th style="display: flex; justify-content: space-around;">
                                <Calendar style="height: 30px; width: 150px;" dateFormat="dd/mm/yy" v-model="fecha_inicio" showIcon iconDisplay="input">
                                    <template #inputicon="{ clickCallback }">
                                        <InputIcon class="pi pi-clock cursor-pointer" @click="clickCallback" />
                                    </template>
                                </Calendar> 
                                <Calendar style="height: 30px; width: 150px;" dateFormat="dd/mm/yy" v-model="fecha_fin" showIcon iconDisplay="input">
                                    <template #inputicon="{ clickCallback }">
                                        <InputIcon class="pi pi-clock cursor-pointer" @click="clickCallback" />
                                    </template>
                                </Calendar>                         
                            </th>
                            <th>

                            </th>
                            <th>
                                <strong>Nº Consultas</strong>
                            </th>
                            <th>
                                <strong>Tramitador</strong>
                            </th>
                            <th>
                                <strong>Asegurado</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="consulta in consultas" :key="consulta">
                            <td style="display: flex; justify-content: space-around;">
                                <p>{{ consulta.codigo }}</p>
                            
                                <p>{{ consulta.nombre }}</p>
                            </td>
                            <td style="text-align: center;">
                                {{ consulta.fecha }}
                            </td>
                            <td>
                                <a href="#" @click="abrir(consulta.enlace)">Enlace</a>
                            </td>
                            <td>
                                {{ consulta.consultas }}
                            </td>
                            <td>
                                <i v-if="consulta.accion_tramitador==1" title="El tramitador ha checkeado todas las comunicaciones del asegurado" class="fas fa-check"></i>
                                <i v-if="consulta.respuesta_tramitador==1" class="fas fa-check" title="El tramitador ha respondido alguna comunicación"></i>
                            </td>
                            <td>
                                <i v-if="consulta.comunicacion_asegurado == 1" class="fas fa-check" title="Se ha realizado una comunicación por parte del Asegurado"></i>
                                <i v-if="consulta.llamada_asegurado == 1" class="fas fa-check" title="Se ha realizado una llamada por parte del Asegurado"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>      
            </div>
        </div>
    </div>
</template>
<script>
import Calendar from 'primevue/calendar';
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    data() {
        return {
            fecha_inicio:'',
            fecha_fin:'',
            consultas: [],
        };
    },
    components: {
        Calendar,
    },
    methods:{
        abrir(url){
            window.open(url, "_blank", "width=800, height=600");
        },
        async obtenerConsultas(){
            const api = new PwgsApi();
            const hoy = new Date();

            var fechaEnMilisegundos = hoy.getTime();

            var restar = 7* 24* 60 * 60000;

            var fechaNueva = new Date(fechaEnMilisegundos - restar);

            this.fecha_inicio = fechaNueva.toLocaleDateString();

            this.fecha_fin = hoy.toLocaleDateString();
            var body = {"fecha_inicio": fechaNueva.toLocaleDateString(), "fecha_fin":hoy.toLocaleDateString()};


            this.consultas = await api.post('ste/consultas', body);
        },
        async obtenerNuevasConsultas(){
            try{
                const api = new PwgsApi();

                try{
                    this.fecha_inicio = this.fecha_inicio.toLocaleDateString();
                }catch(e){
                 console.log(e);
                }

                try{
                    this.fecha_fin = this.fecha_fin.toLocaleDateString();
                }catch(e){
                 console.log(e);
                }


                var body = {"fecha_inicio": this.fecha_inicio, "fecha_fin":this.fecha_fin};
    
    
                this.consultas = await api.post('ste/consultas', body);
                

            }catch(e) {
                alert(e);
            }
        }
    },
    watch: {
        fecha_inicio() {
            this.obtenerNuevasConsultas();
        },
        fecha_fin() {
            this.obtenerNuevasConsultas();
        },
    },
    mounted() {
        this.obtenerConsultas();
    },
};
</script>
