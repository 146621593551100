<!-- OpcionesTab.vue -->
<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Compañías que utilizan Sigue tu Expediente</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-flow: row wrap; width: 70%;">
                    <div style="width: 600px; display: flex; margin-right: 20px;">
                        <label for="comp">Compañía</label>
                        <select style="width: 100%; height: 80%; margin-left: 10px;" id="comp" v-model="companiaSeleccionada">
                             <option v-for="compania in companias" :key="compania.id" :value="compania.id">{{ compania.nombre }}({{ compania.prefijo }})</option>
                        </select>
                    </div>
                    <div style="width: 450px; display: flex;" >
                        <label for="tlf">Telefono</label>
                        <input v-model="telefono" style="width: 100%; height: 80%; margin-left: 10px;" type="text">
                    </div>
                </div>
                <div class="d-flex justify-content-end" style="margin-top: 20px;margin-bottom: 20px; width: 70%;">
                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="guardar">Guardar</button>
                </div>
                <div style="width: 70%;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Compañía</th>
                                <th>Teléfono</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="comp in companiasCreadas" :key="comp.id">
                                <td>{{ comp.nombre }}({{ comp.prefijo }})</td>
                                <td>{{ telefonos[comp.id] }}</td>
                                <td style="text-align: end;">
                                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="eliminar(comp.id)">
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    data() {
        return {
            companias:[],
            companiasCreadas:[],
            companiaSeleccionada:'',
            telefonos:[],
            telefono:'',
        };
    },
    methods:{
        async guardar(){
            if(this.companiaSeleccionada==''){
                alert('Selecciona una compañía');
                return;
            }
            const companiaSeleccion = this.companiaSeleccionada;
            const api = new PwgsApi();
            const body = {"ids_companias": [companiaSeleccion], "telefonos_companias": {[companiaSeleccion]: this.telefono}};
            await api.put('ste', body);
            this.cargarCompaniasCreadas();
        },
        async eliminar(id){
            const api = new PwgsApi();
            const body = {ids_companias:[id]};
            const res = await api.deletebody('ste', body);
            console.log('eliminado',res);
            this.cargarCompaniasCreadas();
        },
        async cargarCompanias(){
            const api = new PwgsApi();
            const res = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = res.datos;
        },
        async cargarCompaniasCreadas(){
            this.companiasCreadas = [];
            const api = new PwgsApi();
            const res = await api.get('ste');
            const ids = res.configuracion.ids_companias;
            const tlfs = res.configuracion.telefonos_companias;
            for(let com of this.companias){
                if (ids.includes(com.id)){
                    this.companiasCreadas.push(com);
                    this.telefonos[com.id] = tlfs[com.id];
                }
            }
        }
    },
    watch: {
        companias(){
            this.cargarCompaniasCreadas();
        }
    },
    mounted() {
        this.cargarCompanias();
        this.cargarCompaniasCreadas();
    },
};
</script>
