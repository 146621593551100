<!-- OpcionesTab.vue -->
<template>    
    <div class="card card-default">
        <div class="card-header">
            <h5>Configuración de encuesta</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div style="display: flex; flex-direction: column;">                
                <div style="display: flex; flex-flow: row wrap; width: 70%; justify-content: space-between;">
                    <p>Compañía</p>
                    <div style="width: 500px;">
                        <select style="width: 100%; height: 80%" id="comp" v-model="companiaSeleccionada">
                            <option value="0">Todas</option>
                            <option v-for="compania in companias" :key="compania.id" :value="compania.id">{{ compania.nombre }}({{ compania.prefijo }})</option>
                        </select>
                    </div>
                </div>
                <div style="display: flex; flex-flow: row wrap; width: 70%; justify-content: space-between;">
                    <p>Encuesta</p>
                    <div style="width: 500px;">
                        <select style="width: 100%; height: 80%" id="comp" v-model="encuestaSeleccionada">
                            <option v-for="encuesta in encuestas" :key="encuesta.id_encuesta" :value="encuesta.id_encuesta">{{ encuesta.encuesta2nombre }}</option>
                        </select>
                    </div>
                </div>
                <div style="display: flex; flex-flow: row wrap; width: 70%; justify-content: space-between;">
                    <p>Enlace reseña</p>
                    <div style="width: 500px;">
                        <input v-model="resena" style="width: 100%; height: 80%" type="text">
                    </div>
                </div>                
                <div class="d-flex justify-content-end" style="margin-top: 20px;margin-bottom: 20px; width: 70%;">
                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="guardar">Guardar</button>
                </div>
                <div style="width: 70%;">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Compañía</th>
                                <th>Encuesta</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="enc in encuestasCreadas" :key="enc.id_compania_encuesta">
                                <td>{{ enc.nombre_compania }}</td>
                                <td>{{ enc.nombre_encuesta }}</td>
                                <td style="text-align: end;">
                                    <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="eliminar(enc.id_compania_encuesta)">
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    data() {
        return {
            companias:[],
            encuestasCreadas:[],
            encuestas:[],
            resena:'',
            companiaSeleccionada:'0',
            encuestaSeleccionada:'',
        };
    },
    methods:{
        async guardar(){
            const api = new PwgsApi();
            if(this.encuestaSeleccionada==''){
                alert('Selecciona una encuesta');
                return;
            }            
            const companiaSeleccion = this.companiaSeleccionada;            
            const body = {"encuestas": {"id_compania":companiaSeleccion, "id_encuesta":this.encuestaSeleccionada, "enlace_resena":this.resena}};
            await api.put('ste', body);
            this.cargarCompaniasCreadas();
        },
        async eliminar(id){
            const api = new PwgsApi();
            const body = {encuestas:[id]};
            const res = await api.deletebody('ste', body);
            console.log('eliminado',res);
            this.cargarCompaniasCreadas();
        },
        async cargarCompanias(){
            const api = new PwgsApi();
            const res = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = res.datos;
            this.encuestas = await api.get('encuestas');
        },
        async cargarCompaniasCreadas(){
            const api = new PwgsApi();
            const res = await api.get('ste');
            this.encuestasCreadas = res.configuracion.encuestas;            
            for(let enc of this.encuestasCreadas){                
                if(enc.id_compania == 0){
                    enc.nombre_compania = 'Todas';
                }
            }
        }
    },
    mounted() {
        this.cargarCompanias();
        this.cargarCompaniasCreadas();
    },
};
</script>
